import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";

export default function () {
  return Promise.all([
    SystemJS.import("cp-client-auth!sofe"),
    SystemJS.import("rxjs"),
    SystemJS.import("rxjs/operators"),
  ]).then((values) => {
    const [authModule, { forkJoin }, { first }] = values;

    forkJoin([
      authModule.default.getLoggedInUserAsObservable().pipe(first()),
      authModule.default.getTenantAsObservable().pipe(first()),
    ]).subscribe(
      ([loggedInUser, tenant]) => {
        if (
          loggedInUser.role !== "Client" &&
          !localStorage.getItem("no-product-tracking")
        ) {
          return setZendeskSettings(tenant, loggedInUser).then(() =>
            loadZendesk(tenant, loggedInUser)
          );
        }
      },
      asyncStacktrace((error) => {
        // No toast on error, this shouldn't be disruptive
        error.showToast = false;
        catchSyncStacktrace(error);
      })
    );
  });
}

function setZendeskSettings() {
  return new Promise((resolve, reject) => {
    Promise.all([
      SystemJS.import("fetcher!sofe"),
      SystemJS.import("canopy-urls!sofe"),
    ]).then(
      ([
        { fetchAsObservable },
        {
          default: { getAPIUrl },
        },
      ]) => {
        window.zESettings = {
          webWidget: {
            authenticate: {
              chat: {
                jwtFn: (callback) => {
                  fetchAsObservable(`${getAPIUrl()}/zendesk-token`).subscribe(
                    ({ jwt }) => {
                      callback(jwt);
                    },
                    asyncStacktrace((error) => {
                      // No toast on error, this shouldn't be disruptive
                      error.showToast = false;
                      catchSyncStacktrace(error);
                    })
                  );
                },
              },
            },
          },
        };
        resolve();
      }
    );
  });
}

function loadZendesk() {
  if (typeof window.zE === "function") {
    //zendesk is already loaded
    //zendesk should reload on login as part of the page load but if it didn't
    //update the settings to be sure the correct auth token is going back
    window.zE("updateSettings", window.zESettings);
  } else {
    const loadZendeskScript = async () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.id = "ze-snippet";
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=27d5d2e3-f2e9-48be-9bf7-dee6fffbcb25`;
      script.onload = () => addZendeskListeners(0);
      document.body.appendChild(script);
    };
    if (document.readyState !== "complete") {
      window.addEventListener("load", loadZendeskScript, false);
    } else {
      try {
        loadZendeskScript();
      } catch (error) {
        asyncStacktrace((error) => {
          // No toast on error, this shouldn't be disruptive
          error.showToast = false;
          catchSyncStacktrace(error);
        });
      }
    }
  }
}

function openZendeskChat(e) {
  //pdfTron opens in an iframe which will throw an error on e.target.matches
  //since e.target is #document in the iFrame, so instead fist determine
  //the click event is not in an iframe
  if(e.target?.activeElement?.tagName === 'IFRAME') return;
  //clicks in app on anything other than zendesk launcher should be ignored
  if (!e.target.matches("#canopy-zendesk-launcher") ) return;
  //we have to show first to allow the widget to be visible
  window.zE("webWidget", "show");
  //then we open to expand the chat widget
  window.zE("webWidget", "open");
}

function addZendeskListeners(numRetries = 0) {
  if (typeof window.zE !== "function") {
    //ensure that the zE function has attached to the window before we start adding event listeners
    if (numRetries < 40) {
      setTimeout(() => addZendeskListeners(numRetries + 1), 100);
    }
    return;
  }
  //add event listener to any click on a button with the "canopy-zendesk-launcher" id attribute
  document.addEventListener("click", openZendeskChat);

  //after initial load we call a hide, to hide the "chat with us" bubble
  window.zE("webWidget", "hide");

  window.zE("webWidget:on", "close", () => {
    //always re-hide the chat bubble
    window.zE("webWidget", "hide");
  });

  window.zE("webWidget:on", "chat:unreadMessages", () => {
    //this will allow the chat bubble to reshow
    //if the user has unread messages
    window.zE("webWidget", "show");
  });
}
