import {featureEnabled} from 'feature-toggles!sofe'
/* Util functions
*/


/* Use this function to check that the hash begins with a certain prefix
 */
export function hashPrefix(location, ...prefixes) {
  return prefixes.some(prefix => location.hash.indexOf(`#/${prefix}`) === 0);
}

/* Use this function to check that the hash matches a string exactly, excluding the query string.
 */
export function hashEquals(location, string) {
  const hashWithoutQuery = location.hash.indexOf('?') >= 0 ? location.hash.slice(0, location.hash.indexOf('?')) : location.hash;
  return hashWithoutQuery === string;
}

/* Use this function to check route-like strings against the URL. The allowed
 * syntax is to us :id inside of your string to indicate that you want to allow
 * for an numeric blob in the url. If we need alphanumeric stuff, let's add an
 * option for that later on
 */
export function containsRoutes(location, ...routes) {
  return routes
    .some(route => {
      const parts = route.split(':id');
      const regex = new RegExp(parts.reduce((result, part, index)=> {
        if (index < parts.length - 1) {
          part = part + `[0-9]+`;
        }

        return result + part;
      }, ''));

      return regex.test(location.hash);
    });
}

/* App-specific implementations that determine if they are active or not.
*/

export function primaryNavbar(location) {
  return !mainLoginPages(location)
    && !supplementalLoginPages(location)
    && !hashEquals(location, '#/contacts/import')
    && !fourHundredPages(location)
    && !webviewUi(location)
    && !location?.pathname?.startsWith('/m/');
}

export function workflowUI(location) {
  const isNotificationsSettingsPage = location.hash.indexOf('#/notifications/settings') === 0;

  const isContactList = hashEquals(location, '#/clients') || hashEquals(location, '#/clients/');
  const isContactCreate = hashPrefix(location, 'new-business') || hashPrefix(location, 'new-individual');

  const otherAppsActive =
    endUserFormsUI(location)
    || lettersUI(location)
    || notificationsUI(location)
    || tasksUI(location)
    || noticesUI(location)
    // Blacklisting GlobalSettings/Communications to prevent 404's from Angular (workflow-ui). Gross...
    // This can be removed when contact record is moved out of workflow UI.
    || globalSettingsCommunications(location)
    || mainLoginPages(location)
    || supplementalLoginPages(location)
    || engagementsList(location)
    || hashPrefix(location, 'files') //This is for global docs, but engagement docs should have workflow
    || calendarUI(location)
    || taxPrepUI(location)
    || clientTaxReturns(location)
    || appDashboardUI(location)
    || canopyAdminUI(location)
    || engagementsReactPortFeature(location)
    || newContactsUI(location)
    || billingReports(location)
    || billingProjects(location)
    || billingOnboarding(location)
    || timeEntries(location)
    || activityLogUI(location)
    || hashPrefix(location, 'inbox')
    || hashPrefix(location, 'transcripts/irs-authorized')
    || hashPrefix(location, 'transcripts/help-center')
    || clientTranscripts(location) // This is for transcripts in contacts
    || webviewUi(location)
    || location.pathname?.startsWith('/m/') // client portal
    || hashPrefix(location, '403')
    || globalSettingsPages(location)
    || pageNotFound()

  return !isContactList && !isContactCreate && !otherAppsActive;
}

function globalSettingsCommunications(location) {
  return hashPrefix(location, 'communications/global-settings');
}

export function endUserFormsUI(location) {
  return hashPrefix(location, 'forms') && !pageNotFound();
}

export function smeQAUI(location) {
  return hashPrefix(location, 'testing') && !pageNotFound();
}

export function contactMenu(location) {
  const inNewSurvey = containsRoutes(location, "/engagements/:id/survey/");
  const isClientPortal =
    window.loggedInUser &&
    window.loggedInUser.role === 'Client'

  return !isClientPortal && onlyLettersByContact(location) && !isEditingNote(location) && !inNewSurvey && containsRoutes(location, "/taxes/client/:id", "/clients/:id", "/client/:id") && !pageNotFound();
}

export function engagementsMenu(location) {
  const inOldSurvey = /mastersurveys/.test(location.hash);
  const inNewSurvey = containsRoutes(location, "engagements/:id/survey");
  const inSurvey = inOldSurvey || inNewSurvey;
  return onlyLettersByContact(location) && !inSurvey && containsRoutes(location, "client/:id/engagements/:id", "clients/:id/engagements/:id") && !pageNotFound();
}

function onlyLettersByContact(location) {
  return (!lettersUI(location) || lettersByContact(location))
}

function lettersByContact(location) {
  return containsRoutes(location, 'letters/list/client/:id/')
}

export function lettersUI(location) {
  return hashPrefix(location, 'letters') && !pageNotFound();
}

export function docsUI(location) {
  return (hashPrefix(location, "docs") || hashPrefix(location, "files")) && !pageNotFound();
}

export function notificationsUI(location) {
  return hashPrefix(location, "notifications") && !pageNotFound();
}

export function pageNotFound() {
  //This try catch is to help us with some undiagnosable IE/Edge issue.
  //Some how this code was causing an "Unspecified error"
  //So we're going to make the assumption that we should return false if it were to die here.
  try {
    return window.history && window.history.state && window.history.state.pageNotFound;
  } catch(e) {
    return false;
  }
}

export function communicationsUI(location) {
   const isActive =
    hashPrefix(location, "communications") ||
    hashPrefix(location, 'inbox');
  return isActive && !pageNotFound();
}

export function contactsUI(location) {
  const isContactList = hashEquals(location, '#/clients') || hashEquals(location, '#/clients/');
  const isContactCreatePage = containsRoutes(location, '#/new-individual', '#/new-business')
  const isContactPage = isContactCreatePage || isContactList || containsRoutes(location, "#/client/:id", "#/communications/clients/:id", "#/docs/clients/:id", "#/tasks/clients/:id", "#/calendar/clients/:id", '#/notices/clients/:id', '#/tax-prep-tasks/clients/:id');
  const isExcludedPage = containsRoutes(location, "client/:id/participants/create", "client/:id/billing", "client/:id/transcripts", "client/:id/time");
  return newContactsUI(location) || (isContactPage && !isExcludedPage && !isEditingNote(location) && !pageNotFound())
}

function newContactsUI(location) {
  return hashPrefix(location, 'contacts')
}

function billingReports(location) {
  return hashPrefix(location, 'billing/reports')
}

function billingProjects(location) {
  return containsRoutes(location, 'billing/projects')
}

function billingOnboarding(location){
  return hashPrefix(location, 'billing/canopy-payments')
}

function timeEntries(location) {
  return containsRoutes(location, 'time')
}

function engagementsList(location) {
  return hashPrefix(location, 'engagements') && !pageNotFound();
}

function engagementsReactPortFeature(location) {
  //Remove this method with the feature toggle.
  //This is just to temporarily block workflow-ui from running on a few specific routes
  if(!featureEnabled('engagements_react_port')) return false
  return (
    (
      containsRoutes(location, 'client/:id/engagements/list')
      || containsRoutes(location, 'client/:id/engagements/create')
    )
    && !pageNotFound()
  );
}

export function engagementsUI(location) {
  return (hashPrefix(location, 'engagements') || containsRoutes(location, 'client/:id/engagements')) && !pageNotFound();
}

export function tasksUI(location) {
  // The endpoint: /global-setting/tax-return should route to TasksUI
  const mainTasks = hashPrefix(location, 'tasks')
  const taskWorkspace = hashPrefix(location, 'task')
  const taxReturnTemplate = hashPrefix(location, 'global-settings/tax-return')
  const clientRequestTemplates = hashPrefix(location, 'templates/client-requests')
  const taskTemplates = hashPrefix(location, 'templates/tasks')
  return (mainTasks || taxReturnTemplate || taskWorkspace || clientRequestTemplates || taskTemplates) && !pageNotFound();
}

export function transcriptsUI(location) {
  return (
    (hashPrefix(location, "transcripts") || containsRoutes(location, '#/client/:id/transcripts')) && !pageNotFound()
  );
}

export function clientTranscripts(location) {
  return (
    containsRoutes(location, '#/client/:id/transcripts') && !pageNotFound()
  );
}

export function globalSettings(location) {
  return (location.hash.indexOf('global-settings') > -1 && !mainLoginPages(location))
}

export function loginUI(location) {
  return onLoginPage(location) || loginRememberMePrompt();
}

export function onLoginPage(location) {
  // Login-ui handles the settings page for white label because the settings page
  // renders previews of the login page.
  const isWhiteLabelRoute = hashPrefix(location, 'white-label')

  return mainLoginPages(location) || isWhiteLabelRoute || supplementalLoginPages(location);
}

export function mainLoginPages(location) {
  return hashPrefix(location, 'login') || onLogoutPage(location) || hashPrefix(location, 'unsupported-browser')
}

function fourHundredPages(location) {
  return hashPrefix(location, '40')
}

export function supplementalLoginPages(location) {
  return hashPrefix(location, 'oauth') || hashPrefix(location, 'select-user')
}

export function onLogoutPage(location) {
  return hashPrefix(location, 'logout')
}

export function calendarUI(location) {
  return hashPrefix(location, 'calendar') && !pageNotFound()
}

export function noticesUI(location) {
  return hashPrefix(location, 'notices') && !pageNotFound()
}

export function taxPrepUI(location) {
  return hashPrefix(location, 'tax-prep-tasks') && !pageNotFound()
}

export function clientTaxReturns(location) {
  return containsRoutes(location, '#/clients/:id/tax-returns')
}

export function billingUI(location) {
  return (
    hashPrefix(location, "billing") ||
    containsRoutes(location, "#/client/:id/billing") ||
    hashPrefix(location, "time") ||
    containsRoutes(location, "#/client/:id/time")
  );
}

export function appDashboardUI(location) {
  return hashPrefix(location, 'home')
}

export function notesUI(location) {
  return containsRoutes(location, '#/client/:id/notes', '#/client/:id/archived-notes')
}

export function canopyAdminUI(location) {
  return hashPrefix(location, 'canopy-admin');
}

export function activityLogUI(location) {
  return hashPrefix(location, 'activity-log') && !pageNotFound();
}

export function webviewUi(location) {
  return hashPrefix(location, 'webview');
}

export function fourHundred(location) {
  return /#\/4\d\d/.test(location.hash);
}

function loginRememberMePrompt() {
  try {
    return !!(window && window.localStorage && window.localStorage.getItem('MFA:showRememberMePrompt'))
  } catch(e) {
    return false;
  }
}

function globalSettingsPages(location) {
  return hashPrefix(location, 'global-settings');
}

function isEditingNote(location) {
  return containsRoutes(location, "/client/:id/notes/:id");
}
